<template>
  <div></div>
</template>

<script>
export default {
  name: 'refresh',
  data() {
    return {}
  },
  created() {
    this.$router.replace({ path: '/' })
  },
  mounted() {},
  methods: {}
}
</script>

<style>
</style>
